import styled from "styled-components";

const Progress=styled.button`
    background-color : var(--bgpink);
    height: 80%;
    width : ${(props) => props.wd};
    border: none;
    border-radius : 5px 0 0 5px;
    margin-left: 1px;
`;

export default Progress 