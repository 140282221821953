import "./ProfileMain.css"
import Img1 from "./mypfim.jpg"

function ProfileMain()
{
    return(
        <div className="App-content">
            <div className="dpcontainer">
                <div id="dp">
                    <img id="pimage" src={Img1} alt="profile"/>
                </div>
            </div>
            <div className="smallintro">
                <span id="smallintro-h1"><span>Hi , </span><span>I'm Preetam</span></span>   
                <h3 id="smallintro-h3">WEB DESIGNER & DEVELOPER</h3>
                <p id="smallintro-p">I'm creative designer based in Gurugram and I'm <br/>very passionate and dedicated to my work</p>
            </div>
            
        </div>
    )
}

export default ProfileMain