import "./Practise.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination,  A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Skillsection1 from "./Appcontent/Skills/Skillsection1";
import Skillsection2 from "./Appcontent/Skills/Skillsection2";
import Skillsection3 from "./Appcontent/Skills/Skillsection3";

function Practise()
{
    SwiperCore.use([Autoplay])
    return(
        <Swiper
        className="practise-container"
        modules={[Navigation, Pagination,A11y]}
        loop={true}
        autoplay={{ delay: 2500 }}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
            >
            <SwiperSlide className="container">
                <Skillsection1/>
            </SwiperSlide >
            <SwiperSlide className="container">
                <Skillsection2/>
            </SwiperSlide>
            <SwiperSlide className="container">
                <Skillsection3/>
            </SwiperSlide>
            
        </Swiper>
    )
}

export default Practise