import "./App.css"

import { Routes, Route } from "react-router-dom";

import Practise from "./Components/Practise"
import ProfileMain from "./Components/Appcontent/Profile/ProfileMain"
import AppmenuMain from "./Components/Appmenu/AppmenuMain"
import AboutMain from "./Components/Appcontent/About/AboutMain"
//import SkillsMain from "./Components/Appcontent/Skills/SkillsMain"
import PortfolioMain from "./Components/Appcontent/Portfolio/PortfolioMain"
import ContactMain from "./Components/Appcontent/Contact/ContactMain";

function App()
{
    return (
        <div className="App-container">
                <Routes>
                    <Route path="/" element={<ProfileMain />}/>
                    <Route path="about" element={<AboutMain />} />
                    <Route path="skills" element={<Practise />} />
                    <Route path="portfolio" element={<PortfolioMain />} />
                    <Route path="contact" element={<ContactMain/>} />
                    
                </Routes>

                <AppmenuMain/>
        </div>
    )
}

export default App