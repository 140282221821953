import "./Menuicon.css"

function Menuicon(props)
{
    return(
        <div className="icon-container">
            <i id="icon" class={props.clas}></i>
            <p className="title">{props.title}</p>
        </div>
    )
}

export default Menuicon