import Menuicon from "./Menuicon";

import { Outlet, Link } from "react-router-dom";
import "./AppmenuMain.css";

function AppmenuMain()
{
    return (
        <div className="appmenu-main">
            <Link className={"linkmain"} to="/" ><Menuicon clas="fa-solid fa-user" title="PROFILE" /></Link>
            <Link className={"linkmain"} to="/about"><Menuicon clas="fa-solid fa-address-card" title="ABOUT ME"/></Link>
            <Link className={"linkmain"} to="/skills"><Menuicon clas="fa-solid fa-gear" title="SKILLS"/></Link>
            <Link className={"linkmain"} to="/portfolio"><Menuicon clas="fa-sharp fa-solid fa-list" title="PORTFOLIO"/></Link>
            <Link className={"linkmain"} to="/contact"><Menuicon clas="fa-brands fa-telegram" title="CONTACT"/></Link>

            <Outlet/>
        </div>
    )
}



/*
            <Menuicon clas="fa-solid fa-user" title="PROFILE" />
            <Menuicon clas="fa-solid fa-address-card" title="ABOUT ME"/>
            <Menuicon clas="fa-solid fa-gear" title="SKILLS"/>
            <Menuicon clas="fa-sharp fa-solid fa-list" title="PORTFOLIO"/>
            <Menuicon clas="fa-brands fa-telegram" title="CONTACT"/>
*/

export default AppmenuMain