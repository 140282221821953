import Scolum from "./Skillcolum"
import "./Skillssection.css"

function Skillsection1()
{
    return(
        <div className="Skillsection">
            <div className="Sheading-container">
                <i class="fa-solid fa-globe Sheading-container-icon"></i>
                <h1 className="Sheading-container-h1"><span className="h1-span">Full Stack</span> Development Languages</h1>
            </div>
            <div className="skillcontent">
                <Scolum title="HTML"  width="75%"/>
                <Scolum title="CSS"  width="70%"/>
                <Scolum title="JAVASCRIPT"  width="80%"/>
                <Scolum title="C"  width="75%"/>
                <Scolum title="C++"  width="85%"/>
                <Scolum title="npm(PM)"  width="75%"/>
                <Scolum title="yarn(PM)"  width="70%"/>
            </div>
        </div>
    )
}

export default Skillsection1