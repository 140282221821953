import "./Portfolioelement.css"

function ProjectMain(props)
{
 /*   let x = window.matchMedia("(max-width: 510px)");
    let img=props.image1;

    function mqr(y)
    {
        if(y.matches)
        {
            img=props.image2;
        }
    }

    mqr(x);*/

    return(
        <div className="project-container">
            <div className="project-content">
                <h1 className="project-contenth1">{props.title}</h1>
                <p className="project-contentp">Website adoptable to all devices, with UI components and animated interactions.</p>
                <div className="project-btn-container">
                    <a href={props.linkpt} target="_blank" rel="noreferrer" className="project-btn-a">Demo &nbsp; <i class="fa-solid fa-arrow-right"></i></a>
                </div>
            </div>
            <div className="project-visual">
                <img className="pj-img-size1" src={props.image1} alt="pjimage"/>
            </div>
        </div>
    )
}

export default ProjectMain