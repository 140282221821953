import Scolum from "./Skillcolum"
import "./Skillssection.css"

function Skillsection3()
{
    return(
            <div className="Skillsection">
                <div className="Sheading-container">
                    <i class="fa-solid fa-database Sheading-container-icon"></i>
                    <h1 className="Sheading-container-h1"><span className="h1-span">Databases</span><br/> SQL / NoSQL</h1>
                </div>
                <div className="skillcontent">
                    <Scolum title="MySQL"  width="80%"/>
                    <Scolum title="Postgre SQL"  width="60%"/>
                    <Scolum title="MONGO DB"  width="70%"/>
                </div>
            </div>
    )
}

export default Skillsection3