import "./PortfolioMain.css"
import ProjectMain from "./Portfolioelement"
import Pj1Img1 from "./pj-1-img-1.png"
import Pj1Img2 from "./pj-1-img-2.jpg"
import Pj2Img1 from "./pj-2-img-1.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination,  A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Pagination ,
function PortfolioMain()
{
    SwiperCore.use([Autoplay])
    return(
        <Swiper
        className="Portfolio-container"
        modules={[Navigation, Pagination ,A11y]}
        loop={true}
        autoplay={{ delay: 2000 }}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
            >
            <SwiperSlide >
                <ProjectMain title="Gym Website" image1={Pj1Img1} image2={Pj1Img2} linkpt="https://chiselfitclub.pages.dev/"/>
            </SwiperSlide >
            <SwiperSlide >
                <ProjectMain title="E-commerce Website" image1={Pj2Img1} linkpt="https://amazonpurecosmetics.pages.dev/"/>
            </SwiperSlide>            
        </Swiper>
    )
}

export default PortfolioMain