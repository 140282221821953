import "./ContactMain.css"

function ContactMain()
{
    return(
        <div className="Contactmain-container">
            <div className="contact-form-container">
                <form target="_blank" id="contact-form" action="https://formsubmit.co/preetambhardwajdoc12@gmail.com" method="POST">
                    <div className="contact-form-section">
                        <div className="contact-form-section-row">
                            <span className="contact-form-row-span">First Name :</span>
                            <input className="contact-form-row-input" type="text" id="fname" name="fname" required/>
                        </div>
                        <div className="contact-form-section-row">
                            <span className="contact-form-row-span">Last Name :</span>
                            <input className="contact-form-row-input" type="text" id="lname" name="lname" />
                        </div>
                    </div>
                    <div className="contact-form-section">
                        <div className="contact-form-section-row">
                            <span className="contact-form-row-span">Email :</span>
                            <input className="contact-form-row-input" type="email" id="email" name="email" required/>
                        </div>
                        <div className="contact-form-section-row">
                            <span className="contact-form-row-span">Mobile :</span>
                            <input className="contact-form-row-input" type="number" id="mobile" name="mobile" required/>
                        </div>
                    </div>
                    <div className="contact-form-message">
                        <span className="contact-form-message-span">Message :</span>
                        <textarea id="message" name="message" placeholder="Enter Message here..." rows="4" cols="50" maxlength="300" required></textarea>
                    </div>
                    <div id="button-container">
                        <button type="submit" className="form-button">Send</button>
                        <button type="reset" className="form-button">Reset</button>
                    </div>
                    
                </form>
            </div>
            <div id="contactinfo-container">
                <h1 id="contactinfo-h1">Contact Info</h1>
                <div className="icon-data-container">
                    <i class="fa-solid fa-location-dot icon-data-container-i"></i>
                    <p className="icon-data-container-p" >439/31, Gali No. - 2, Laxman Vihar, Gurgaon, Haryana 122001</p>
                </div>
                <div className="icon-data-container" id="gmailid">
                    <i class="fa-solid fa-envelope icon-data-container-i"></i>
                    <p className="icon-data-container-p">preetambhardwajdoc12@gmail.com</p>
                </div>
                <div className="icon-data-container">
                    <i class="fa-solid fa-phone icon-data-container-i"></i>
                    <p className="icon-data-container-p">7838496045</p>
                </div>
                <div className="social-media-icons-container">
                    <a href="" className="social-media-icon-a">
                        <i class="fa-brands fa-linkedin social-media-icon-i"></i>
                    </a>
                    <a href="" className="social-media-icon-a">
                        <i class="fa-brands fa-github social-media-icon-i"></i>
                    </a>
                    <a href="" className="social-media-icon-a">
                        <i class="fa-brands fa-facebook social-media-icon-i"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ContactMain