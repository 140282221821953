import "./AboutMain.css"

function AboutMain()
{
    return(
        <div className="AboutMain-container">
            <div className="aboutsection">
                <h4> NAME : PREETAM</h4>
                <h4> DOB : 12-11-2001</h4>
                <h4> FATHER's NAME : Lt. MR. SAJJAN KUMAR</h4>
                <h4> MOTHER's NAME : Mrs. Anita</h4>
                <h4> Phone : 7838496045</h4>
                <h4> ADDRESS : 439/31 , Gali No. - 2 , <br/>Laxman Vihar Gurgaon Haryana 122001</h4>
                <h4> EMAIL : preetambhardwajdoc12@gmail.com</h4>
            </div>
            <div className="aboutsection">
                <h4> EDUCATIONAL DETAILS </h4>
                <h4> 10th : ST. CRISPIN's SCHOOL, GURUGRAM<br/>CGPA - 8.2</h4>
                <h4> 12th : ST. CRISPIN's SCHOOL, GURUGRAM<br/>84%</h4>
                <h4> GRADUATION : B.TECH (CSE) (2019-2023)</h4>
                <h4> COLLEGE : DRONACHARYA COLLEGE OF ENGINEERING , GURUGRAM</h4>
                <h4 id="btndwn"><button id="dwncv">Download CV</button></h4>
                
            </div>
        </div>
    )
}

export default AboutMain