import Scolum from "./Skillcolum"
import "./Skillssection.css"

function Skillsection2()
{
    return(
        <div className="Skillsection">
            <div className="Sheading-container">
                <i class="fa-sharp fa-solid fa-toolbox Sheading-container-icon"></i>
                <h1 className="Sheading-container-h1"><span className="h1-span">Full Stack</span> Development Skills</h1>
            </div>
            <div className="skillcontent">
                <Scolum title="GIT"  width="80%"/>
                <Scolum title="Bootstrap"  width="70%"/>
                <Scolum title="TAILWIND"  width="65%"/>
                <Scolum title="ReactStrap"  width="65%"/>
                <Scolum title="Material UI"  width="70%"/>
                <Scolum title="REACT"  width="80%"/>
                <Scolum title="AJAX"  width="75%"/>
            </div>
        </div>
    )
} 

export default Skillsection2