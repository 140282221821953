import "./Skillcolum.css"
import Progress from "./Progressprop"

function Scolum(props)
{
    return (
        <div className="colum-container">
            <div className="titlecc">{props.title}</div>
            <div className="progress-container">
                <Progress bg={props.bg} wd={props.width}/>
            </div>
            
        </div>
    )
}

export default Scolum